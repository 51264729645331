function dosmth(camera_id) { 
  let locale = document.getElementsByTagName('body').locale.dataset.locale
  var compare_ids, arr_compares, counter

  console.log("init",localStorage.getItem("compare_ids"))
    // если уже есть compare_ids
    if (JSON.parse(localStorage.getItem("compare_ids"))) {

      compare_ids = JSON.parse(localStorage.getItem("compare_ids"))
      arr_compares = Array.from(compare_ids)
      counter = arr_compares.length
        // если уже есть и кол-во устраивает
      if (counter < 2) {
        // добавляю в массив полученный id
        arr_compares.push(camera_id)
        localStorage.setItem("compare_ids", JSON.stringify(arr_compares))

        counter = arr_compares.length

        toastr.options.closeMethod = 'fadeOut';
        toastr.options.closeDuration = 500;
        toastr.options.closeEasing = 'swing';
        if (locale == "ru") {
          toastr.success('Две камеры добавлены для сравнения! Нажмите на кнопку Перейти к сравнению')
        } else {
          toastr.success('Added two to comparison! Click "Go to Compare" button on Top')
        }
      } else {
        // если кол-во больше 2
        // document.getElementById("btnCompare").remove();
        counter = arr_compares.length
        toastr.options.closeMethod = 'fadeOut';
        toastr.options.closeDuration = 500;
        toastr.options.closeEasing = 'swing';
        toastr.options.progressBar = true;
        if (locale == "ru") {
          toastr.warning('Достигнут максимум! Мы можем сравнить только две камеры. У вас уже выбраны две камеры для сравнения, перейдите к сравнению и удалите из сравнения лишнее.')
        } else {
          toastr.warning('Max compare items! We can compare only 2x')
        }
      }
      
    } else {
      // если пустое хранилище
      arr_compares = []

        arr_compares.push(camera_id)
        counter = arr_compares.length
        console.log("counter", counter)
        localStorage.setItem("compare_ids", JSON.stringify(arr_compares))

        toastr.options.closeMethod = 'fadeOut';
        toastr.options.closeDuration = 500;
        toastr.options.closeEasing = 'swing';
        if (locale == "ru") {
          toastr.success('Камера добавлена к сравнению!')
        } else {
          toastr.success('Added one to comparison!')
        }

        if (document.getElementById('btnCompare')) {
          document.getElementById('compare_counter').innerHTML = counter
        }
    }

    console.log("counter:", counter)
    if (counter == 2 && !document.getElementById('compare_button') && document.getElementById('buttons_group')) {
      var a = document.createElement('a');
      // a.target = '_blank'
      a.id = 'compare_button'
      a.classList.add("btn","btn-warning")
      
      if (locale == "ru") {
        a.href = '/ru/compare/' + arr_compares[0] + "-with-" + arr_compares[1];
        a.innerText = 'Перейти к сравнению';
      } else {
        a.href = '/compare/' + arr_compares[0] + "-with-" + arr_compares[1];
        a.innerText = 'Go to Comparison';
      }
    
      var container = document.getElementById('buttons_group');
      container.appendChild(a);
      container.appendChild(document.createElement('br'));
    }

    if (counter == 2 ) {
      var a = document.getElementById("my_comparison_btn")
      document.getElementById("my_comparison_btn").remove();
      // a.target = '_blank'
      a.classList.remove("btn-light")
      a.classList.add("btn-warning")
      
      if (locale == "ru") {
        a.href = '/ru/compare/' + arr_compares[0] + "-with-" + arr_compares[1];
        a.innerText = 'Перейти к сравнению';
      } else {
        a.href = '/compare/' + arr_compares[0] + "-with-" + arr_compares[1];
        a.innerText = 'Go to Comparison';
      }
      var container = document.getElementById('header_button_group');
      container.appendChild(a)
    }
    // if (counter == 2 ) {
    //   Turbolinks.visit('/compare/' + arr_compares[0] + "-with-" + arr_compares[1])
    // }

    // показываю кол-во сравнений
    if (document.getElementById('compare_counter')) {
      document.getElementById('compare_counter').innerHTML = counter
    }
}

export default dosmth;
// document.addEventListener('turbolinks:load', () => {  
//   const clickButton = document.getElementById("button-click");  

//   clickButton.addEventListener("click", dosmth); 
// });
