import { Controller } from "stimulus";

import algoliasearch from "algoliasearch";
import autocomplete from "autocomplete.js";

export default class extends Controller {
  static targets = ["searchBar"];

  connect() {
    var client = algoliasearch(
      this.searchBarTarget.dataset.algoliaId,
      this.searchBarTarget.dataset.algoliaSearchKey
    );
    var index = client.initIndex("Camera");

    function newHitsSource(index, params) {
      return function doSearch(query, cb) {
        index
          .search(query, params)
          .then(function (res) {
            cb(res.hits, res);
          })
          .catch(function (err) {
            console.error(err);
            cb([]);
          });
      };
    }
    // Set hint: true to false, if you don't want a predictive hint to show up for users as they type
    autocomplete("#search-input", { hint: true }, [
      {
        // Change hitsPerPage as needed
        source: newHitsSource(index, { hitsPerPage: 10 }),
        displayKey: "full_name",
        // templates: {
        //   item({ item, createElement }) {
        //     return createElement('div', {
        //       dangerouslySetInnerHTML: {
        //         __html: `<div>
        //           <img
        //             src="${item.main_camera_image}"
        //             alt="${item.full_name}"
        //           />
        //         </div>
        //         <div>
        //           ${item.full_name}
        //         </div>`,
        //       },
        //     });
        //   },
        // },
        templates: {
          suggestion: function (suggestion) {
            // Change the return here to whatever you wish to be displayed in the dropdown
            return `
            <div class="media mb-1">
              <img src="${suggestion.main_camera_image}" alt="${suggestion.full_name}" style="max-height: 50px;" />
              <div class="media-body my-auto m-2 right_section">
              ${suggestion._highlightResult.full_name.value}
                    <p>${suggestion._highlightResult.subcategory.value}
                    </p>
              </div>
            </div>  
            `;
          },
        },
      },
    ]).on(
      "autocomplete:selected",
      function (event, suggestion, dataset, context) {
        // console.log(event, suggestion, dataset, context);
        console.log(suggestion);
        Turbolinks.visit(`/cameras/${suggestion.slug}`);
      }
    );
  }
}
