// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")

// require("packs/search")
require("controllers")

global.toastr = require("toastr")
toastr.options.closeButton = true;
toastr.options.timeOut = 100;
toastr.options.progressBar = true;
toastr.options = {
  "positionClass": "toast-top-full-width"
}

import 'bootstrap'
// import 'bootstrap/dist/css/bootstrap.css'
// import 'easy-autocomplete/dist/jquery.easy-autocomplete'
import './stylesheets/application.scss'



// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
$(document).on('turbolinks:load', function() {

  // Adsense
  var ads = document.querySelectorAll('.adsbygoogle');

  if (ads.length > 0) {
    ads.forEach(function (ad) {
      if (ad.firstChild) {
        ad.removeChild(ad.firstChild);
      }
      window.adsbygoogle = window.adsbygoogle || [];
      window.adsbygoogle.push({});
    });
  }

  let locale = document.getElementsByTagName('body').locale.dataset.locale

  var maxField = 10; //Input fields increment limitation
    var addButton = $('.add_button'); //Add button selector
    var wrapper = $('.field_wrapper'); //Input field wrapper
    var fieldHTML = '<input name="camera[series_history][]" multiple="multiple" class="form-control" type="text" id="camera_series_history"><a href="javascript:void(0);" class="remove_button">delete field</a>'; //New input field html 
    var x = 1; //Initial field counter is 1
    
    //Once add button is clicked
    $(addButton).click(function(){
        //Check maximum number of input fields
        if(x < maxField){ 
            x++; //Increment field counter
            $(wrapper).append(fieldHTML); //Add field html
        }
    });
    
    //Once remove button is clicked
    $(wrapper).on('click', '.remove_button', function(e){
        e.preventDefault();
        $(this).parent('td').remove(); //Remove field html
        x--; //Decrement field counter
    });

  // $("input").easyAutocomplete({data:["red","green"]});

  $('body').tooltip({
    selector: '[data-toggle="tooltip"]',
    container: 'body',
  });
  $('body').popover({
    selector: '[data-toggle="popover"]',
    container: 'body',
    html: true,
    trigger: 'hover',
  });

  // Set empty compare_ids
  // localStorage.setItem("compare_ids", JSON.stringify([]))
  if (localStorage.getItem("compare_ids")) {
    var compare_ids = JSON.parse(localStorage.getItem("compare_ids"))
    var arr_compares = Array.from(compare_ids)
    var counter = arr_compares.length
    document.getElementById('compare_counter').innerHTML = counter

    if (counter == 2 && !document.getElementById('compare_button') && document.getElementById('buttons_group')) {
      var a = document.createElement('a');
      // a.target = '_blank'
      a.id = 'compare_button'
      a.classList.add("btn","btn-warning")
      
      if (locale == "ru") {
        a.href = '/ru/compare/' + arr_compares[0] + "-with-" + arr_compares[1];
        a.innerText = 'Перейти к сравнению';
      } else {
        a.href = '/compare/' + arr_compares[0] + "-with-" + arr_compares[1];
        a.innerText = 'Go to Comparison';
      }
    
      var container = document.getElementById('buttons_group');
      container.appendChild(a);
      container.appendChild(document.createElement('br'));

      document.getElementById("btnCompare").remove();
    }

    if (counter == 2 ) {
      var a = document.getElementById("my_comparison_btn")
      document.getElementById("my_comparison_btn").remove();
      // a.target = '_blank'
      a.classList.remove("btn-light")
      a.classList.add("btn-warning")
      
      if (locale == "ru") {
        a.href = '/ru/compare/' + arr_compares[0] + "-with-" + arr_compares[1];
        a.innerText = 'Перейти к сравнению';
      } else {
        a.href = '/compare/' + arr_compares[0] + "-with-" + arr_compares[1];
        a.innerText = 'Go to Comparison';
      }
      var container = document.getElementById('header_button_group');
      container.appendChild(a)
    }
    // if (counter == 2 ) {
    //   Turbolinks.visit('/compare/' + arr_compares[0] + "-with-" + arr_compares[1])
    // }

  } else {
    document.getElementById('compare_counter').innerHTML = "0"
  }

  $('[data-toggle="tooltip"]').tooltip();

  });

  // Google analitics
    // gtag('config', 'UA-8764166-11', {'page_location': event.data.url})
  
  import dosmth from './custom'
  window.dosmth = dosmth
  import removeCompare from './remove_compare'
  window.removeCompare = removeCompare
