function removeCompare() {
  let locale = document.getElementsByTagName('body').locale.dataset.locale
  localStorage.removeItem('compare_ids')
  if (locale == 'ru') {
    Turbolinks.visit('/ru/cameras')
  } else {
    Turbolinks.visit('/cameras')
  }
}

export default removeCompare;
